import Vue from 'vue';

const files = require.context('../Components', true, /\.vue$/i);

document.addEventListener('turbolinks:load', () => {
  const el = document.querySelectorAll('[data-vue-component-path]')[0];
  if (el != null) {
    const path = el.dataset.vueComponentPath
    const component = files(`./${path}.vue`).default
    const props = el.dataset.props
    const globals = el.dataset.globals
    //Vue.prototype.$globals = JSON.parse(globals)
    const app = new Vue({
      el,
      render: h => h(component, {
        props: JSON.parse(props)
      })
    })
  }
});
