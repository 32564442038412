<template>
  <div class="form-group">
    <label class="control-label" for="">Categories</label>
    <VSelect taggable multiple push-tags
      :options="model.categories"
      v-model="model.selected"
      label="name"
      @input="saveSelected"
      />
  </div>
</template>

<script>
import Api from 'custom/api';
import VSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
export default {
  props: ['all_categories', 'categories', 'id'],
  components: {
    VSelect
  },
  data() {
    return {
      model: {
        categories: this.all_categories,
        selected: this.categories
      }
    }
  },
  methods: {
    saveSelected() {
      Api().post("/admin/article_categories", {
        categories: this.model.selected,
        article_id: this.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
